import { useEffect, useState } from 'react';
import './carga.css'
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import PagosPdf from '../pdf/Pagos';
import { PDFDownloadLink } from '@react-pdf/renderer';
export default function Sicobe({ area }) {
    const [cookies] = useCookies(["user"]);
    const id = useParams().id;
    const [sicobe, setSicobe] = useState([]);
    const [pagosAnteriores1, setPagosAnteriores1] = useState([])
    const [cobert, setCobert] = useState('')
    const [cargando, setCargando] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const resSicobe = await axios.get(`https://back.agsseguros.online/api/pagosContractuales/${id}`);
                setSicobe(resSicobe.data);
                // pagos que existen
                const pagos = resSicobe.data.pagos;
                // plan de pagos
                const arrayFechaLimite = resSicobe.data.fechaLimiteWe
                const fechaStro = new Date(resSicobe.data.fechaStro);
                const pagosAnteriores = [];
                // por cada elemento del array fechaLimite (plan de pagos), si la fecha del stro fue mayor que la fecha limite, se pushea dicho elemento
                for (let i = 0; i < arrayFechaLimite.length; i++) {
                    if (new Date(arrayFechaLimite[i]) < fechaStro) {
                        pagosAnteriores.push([i]);
                    }
                }
                setPagosAnteriores1(pagosAnteriores);
                const totalImporteFechaStro = pagos.slice(0, pagosAnteriores1.length).map(item => parseFloat(item?.pga_importe) || 0).reduce((acc, importe) => acc + importe, 0);
                const totalImporteEsperado = (parseFloat(pagos[0]?.pga_importe) || 0) * pagosAnteriores1.length;
                if (pagos.length === 0) {
                    setCobert('CON COBERTURA')
                } else if (pagos[0]?.pga_mp === 'TA') {
                    setCobert('CON COBERTURA');
                } else if (totalImporteFechaStro !== totalImporteEsperado) {
                    setCobert('EN REVISION');
                } else if (pagosAnteriores.length > 0 && pagosAnteriores.length <= pagos.length) {
                    const ultimoPagoAnterior = pagos[pagosAnteriores.length - 1];
                    const pgaFechaAlta = new Date(ultimoPagoAnterior.pga_fliqags);
                    const fechaLimite = new Date(arrayFechaLimite[pagosAnteriores.length - 1]);
                    if (pgaFechaAlta <= fechaLimite) {
                        setCobert('CON COBERTURA');
                    } else if (pgaFechaAlta > fechaLimite) {
                        if (fechaStro >= pgaFechaAlta) {
                            setCobert('CON COBERTURA');
                        } else {
                            setCobert('EN REVISION');
                        }
                    }
                } else {
                    setCobert('EN REVISION')
                }
                setCargando(true);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
        fetchData();
    }, [id]);
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    const openInNewTab = (url, e) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <>
            {cargando === true ?
                <>
                    <table class="tablaOtrasDenunciasContainer table">
                        <thead>
                            <tr>
                                <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Organiz</h6></th>
                                <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Recibo</h6></th>
                                <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Nrocc</h6></th>
                                <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Fecha</h6></th>
                                <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Importe</h6></th>
                            </tr>
                        </thead>
                        <tbody>
                            {sicobe.pagos && sicobe.pagos.length > 0 ? sicobe.pagos.map(d => {
                                return <tr>
                                    <td className='textoBodyTablaOtrasDenuncias'>{d.pga_organiz}</td>
                                    <td className='textoBodyTablaOtrasDenuncias2'>{d.pgo_recibo}</td>
                                    <td className='textoBodyTablaOtrasDenuncias2'>{d.pga_nrocc}</td>
                                    <td className='textoBodyTablaOtrasDenuncias2'>{formatDate(d.pga_fliqags)}</td>
                                    <td className='textoBodyTablaOtrasDenuncias'>{d.pga_importe}</td>
                                </tr>
                            }) : null}
                        </tbody>
                    </table>
                    {sicobe?.otrasDenunciasQnx ? <div className='mt-5'>
                        <h5>Misma Patente</h5>
                        <table className='tablaOtrasDenunciasContainer table'>
                            <thead>
                                <tr>
                                    <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Patente</h6></th>
                                    <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Poliza/Prop</h6></th>
                                    <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Inicio Vig</h6></th>
                                    <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Fin Vig</h6></th>
                                    <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Cant Pagos</h6></th>
                                    <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Cobertura</h6></th>
                                    <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Anu/Rehab</h6></th>
                                    <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Asegurado</h6></th>
                                    <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Suma Asegurada</h6></th>
                                    <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Fecha Emision</h6></th>
                                </tr>
                            </thead>
                            <tbody>
                                {sicobe?.otrasDenunciasQnx?.porPatente?.length > 0 ? sicobe?.otrasDenunciasQnx?.porPatente?.map(d => {
                                    return <tr>
                                        <td className='textoBodyTablaOtrasDenuncias'>{d.ados_patente}</td>
                                        <td className='textoBodyTablaOtrasDenuncias2'><Link onClick={(e) => openInNewTab(`https://www.agsnet.com.ar/muestro-polizas2.php?prop=${d.ados_nro_cc}&rtn=consulta-polizas`)}>{d.ados_poliza}</Link></td>
                                        <td className='textoBodyTablaOtrasDenuncias2'>{formatDate(d.ados_fech_ini)}</td>
                                        <td className='textoBodyTablaOtrasDenuncias2'>{formatDate(d.ados_fech_fin)}</td>
                                        <td className='textoBodyTablaOtrasDenuncias'>{d?.pagos?.length}</td>
                                        <td className='textoBodyTablaOtrasDenuncias'>{d.ados_cobert}</td>
                                        <td className='textoBodyTablaOtrasDenuncias'>{d.anurehab[0]?.anu_tipo === 1 ? 'A' : (d.anurehab[0]?.anu_tipo === 2 ? 'R' : null)}</td>
                                        <td className='textoBodyTablaOtrasDenuncias'>{d.ados_nom_aseg}</td>
                                        <td className='textoBodyTablaOtrasDenuncias'>{d.ados_sum_aseg}</td>
                                        <td className='textoBodyTablaOtrasDenuncias'>{formatDate(d.ados_fech_emi)}</td>
                                    </tr>
                                }) : null}
                            </tbody>
                        </table>
                    </div> : <></>}
                    <PDFDownloadLink document={<PagosPdf sicobe={sicobe} />} fileName={`pagosStro${sicobe?.nroStro?.toString().substring(3)}.pdf`}>
                        {({ loading, url, error, blob }) =>
                            <div className='center me-2'><button id="botonFrente" className='btn btn-primary btn-sm'>Descargar Pagos</button></div>
                        }
                    </PDFDownloadLink>
                </> :
                <div className="center"><div class="spinner-border text-success center" role="status"><span class="visually-hidden center">Loading...</span></div></div>}
        </>
    );
}


