import { useEffect, useState } from "react";
import "./frente.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import FrentePdf from '../pdf/Frente'
export default function Frente({ }) {
    const id = useParams().id;
    const [denuncia, setDenuncia] = useState([]);
    const [cargando, setCargando] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const resDenuncia = await axios.get(
                    `https://back.agsseguros.online/api/frente/${id}`
                );
                setDenuncia(resDenuncia.data);
                setCargando(true);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
        fetchData();
    }, [id]);
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    const ivaNros = [
        { id: 1, NOMBRE: "CONSUMIDOR FINAL" },
        { id: 2, NOMBRE: "LIBERADO" },
        { id: 3, NOMBRE: "NO RESP/EXENTO" },
        { id: 4, NOMBRE: "RESP NO INSCRIPTO" },
        { id: 5, NOMBRE: "RESP INS NO AG. RET" },
        { id: 6, NOMBRE: "RESP INSC GR/CONTR" },
        { id: 7, NOMBRE: "RESP INS AG. RET" },
        { id: 8, NOMBRE: "NO CATEG" },
        { id: 9, NOMBRE: "MONOTRIBUTO" },
    ];
    function getNombreById(id) {
        const result = ivaNros.find((item) => item.id === id);
        return result ? result.NOMBRE : null;
    }
    const iva = getNombreById(denuncia?.qnx?.ados_tip_iva);
    const jurisdiccionPas = ["", "Persona Fisica", "Persona Juridica"];
    const ingresosBrutosPas = [
        "",
        "No Inscripto",
        "Juridiccional",
        "Convenio Multilateral",
    ];
    const tipoJubilacionPas = [
        "",
        "No Inscripto",
        "Industria y Comercio",
        "Autonomo",
    ];
    const serSocialPas = [
        "",
        "No Afiliado",
        "Personas Fisicas",
        "Personas Juridicas",
    ];
    const ivaPas = [
        "",
        "CONSUMIDOR FINAL",
        "LIBERADO",
        "NO RESP/EXENTO",
        "RESP NO INSCRIPTO",
        "RESP INS NO AG. RET",
        "RESP INSC GR/CONTR",
        "RESP INS AG. RET",
        "NO CATEG",
        "MONOTRIBUTO",
    ];
    return (
        <>
            {cargando ? (
                <>
                    <section>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <h6 className="titulo">NRO STRO:</h6>
                                <h6 className="titulo ms-2">
                                    {denuncia?.nroStro?.toString().substring(3)}
                                </h6>
                            </div>
                            <div className="d-flex">
                                <h6 className="titulo">FECHA CARGA:</h6>
                                <h6 className="titulo ms-2">
                                    {formatDate(denuncia?.fechaCarga)}
                                </h6>
                            </div>
                            <div className="d-flex">
                                <h6 className="titulo">FECHA STRO:</h6>
                                <h6 className="titulo ms-2">
                                    {formatDate(denuncia?.fechaStro)}
                                </h6>
                            </div>
                            <div className="d-flex">
                                <h6 className="titulo">SELLO:</h6>
                                <h6 className="titulo ms-2">
                                    {formatDate(denuncia?.fechaDenuncia)}
                                </h6>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <h6 className="titulo2">POLIZA:</h6>
                                <h6 className="subTitulo2 ms-2">{denuncia?.qnx?.ados_poliza}</h6>
                            </div>
                            <div></div>
                            <div className="d-flex">
                                <h6 className="titulo2">END:</h6>
                                <h6 className="subTitulo2 ms-2">{denuncia?.qnx?.ados_endoso}</h6>
                            </div>
                            <div></div>
                            <div className="d-flex">
                                <h6 className="titulo2">PROP:</h6>
                                <h6 className="subTitulo2 ms-2">{denuncia?.qnx?.ados_nro_cc}</h6>
                            </div>
                            <div></div>
                            <div className="d-flex">
                                <h6 className="titulo2">STROS:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {denuncia?.otrasDenuncias.porPatente.length > 1 ||
                                        denuncia?.otrasDenuncias.porPoliza.length > 1
                                        ? "SI"
                                        : "NO"}
                                </h6>
                            </div>
                            <div></div>
                        </div>
                        <div className="bordeNegro mb-3"></div>
                        <div className="row">
                            <div className="col-8 d-flex align-items-center">
                                <h6 className="titulo2">PRODUCTOR:</h6>
                                <h6 className="subTitulo2 ms-2">{`${denuncia?.productor?.pro_productor} - ${denuncia?.productor?.pro_raz_social}`}</h6>
                            </div>
                            <div className="col-4 d-flex align-items-center">
                                <h6 className="titulo2">DOM.PAS:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {denuncia.productor?.pro_domicilio}
                                </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 d-flex align-items-center">
                                <h6 className="titulo2">LOCALIDAD:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {denuncia.productor?.pro_localidad}
                                </h6>
                            </div>
                            <div className="col-4 d-flex align-items-center">
                                <h6 className="titulo2">CP:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {denuncia.productor?.pro_nro_cuit}
                                </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">CATEGORIA IVA:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {ivaPas[Number(denuncia?.productor?.pro_cat_iva)]}
                                </h6>
                            </div>
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">CUIT:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {denuncia?.productor?.pro_nro_cuit}
                                </h6>
                            </div>
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">NRO JUB:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {denuncia?.productor?.pro_nro_jub}
                                </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">TIPO JUBILACION:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {
                                        tipoJubilacionPas[
                                        Number(denuncia?.productor?.pro_jubilacion)
                                        ]
                                    }
                                </h6>
                            </div>
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">ING BRUTOS:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {
                                        ingresosBrutosPas[
                                        Number(denuncia?.productor?.pro_ing_brutos)
                                        ]
                                    }
                                </h6>
                            </div>
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">NRO IB:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {denuncia?.productor?.pro_nro_ingbto}
                                </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">JURISDICCION PAS:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {jurisdiccionPas[Number(denuncia?.productor?.pro_cod_jur)]}
                                </h6>
                            </div>
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">SER. SOCIAL:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {serSocialPas[Number(denuncia?.productor?.pro_ser_social)]}
                                </h6>
                            </div>
                        </div>
                        <div className="bordeNegro mb-3"></div>
                        <div className="row">
                            <div className="col-9 d-flex align-items-center">
                                <h6 className="titulo2">ASEGURADO:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {denuncia?.qnx?.ados_nom_aseg}
                                </h6>
                            </div>
                            <div className="col-3 d-flex align-items-center">
                                <h6 className="titulo2">CUIT:</h6>
                                <h6 className="subTitulo2 ms-2">{denuncia?.qnx?.ados_cuit}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-9 d-flex align-items-center">
                                <h6 className="titulo2">DOMICILIO:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {denuncia?.qnx?.ados_dir_aseg}
                                </h6>
                            </div>
                            <div className="col-3 d-flex align-items-center">
                                <h6 className="titulo2">CP:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {denuncia?.qnx?.ados_cdp_aseg}
                                </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">LOCALIDAD:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {denuncia?.qnx?.ados_loc_aseg}
                                </h6>
                            </div>
                        </div>
                        <div className="bordeNegro mb-3"></div>
                        <div className="row">
                            <div className="col-6 d-flex align-items-center">
                                <h6 className="titulo2">PATENTE:</h6>
                                <h6 className="subTitulo2 ms-2">{denuncia?.qnx?.ados_patente}</h6>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <h6 className="titulo2">AÑO:</h6>
                                <h6 className="subTitulo2 ms-2">{denuncia?.qnx?.ados_modelo}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">ITEM:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {denuncia?.item ? denuncia?.item[0]?.item_desc : null}
                                </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">VEHICULO:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {denuncia?.qnx?.ados_vehiculo}
                                </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 d-flex align-items-center">
                                <h6 className="titulo2">USO:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {denuncia?.uso ? denuncia.uso[0]?.uso_desc : null}
                                </h6>
                            </div>
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">MOTOR:</h6>
                                <h6 className="subTitulo2 ms-2">{denuncia?.qnx?.ados_motor}</h6>
                            </div>
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">CHASIS:</h6>
                                <h6 className="subTitulo2 ms-2">{denuncia?.qnx?.ados_chasis}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">SUMA ASEG:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {denuncia?.qnx?.ados_sum_aseg}
                                </h6>
                            </div>
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">TIPO STRO:</h6>
                                <h6 className="subTitulo2 ms-2">{denuncia?.catStro}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 d-flex align-items-center">
                                <h6 className="titulo2">COD RIESGO:</h6>
                                <h6>{"-----"}</h6>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <h6 className="titulo2">VALOR GNC:</h6>
                                <h6 className="subTitulo2 ms-2">{`${denuncia?.qnx?.ados_eq_gas}`}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 d-flex align-items-center">
                                <h6 className="titulo2">SICOBE:</h6>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <h6 className="titulo2">IVA:</h6>
                                <h6 className="subTitulo2 ms-2">{`${denuncia?.qnx?.ados_tip_iva} - ${iva}`}</h6>
                            </div>
                        </div>
                        <div className="bordeNegro mb-3"></div>
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">POLIZA:</h6>
                                <h6 className="subTitulo2 ms-2">{denuncia?.qnx?.ados_poliza}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 d-flex align-items-center">
                                <h6 className="titulo2">INI.VIG:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {formatDate(denuncia?.qnx?.ados_fech_ini)}
                                </h6>
                            </div>
                            <div className="col-4 d-flex align-items-center">
                                <h6 className="titulo2">FECHA EMISION:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {formatDate(denuncia?.qnx?.ados_fech_emi)}
                                </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 d-flex align-items-center">
                                <h6 className="titulo2">FIN.VIG:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {formatDate(denuncia?.qnx?.ados_fech_fin)}
                                </h6>
                            </div>
                            <div className="col-4 d-flex align-items-center">
                                <h6 className="titulo2">FECHA EMI.PROP:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {formatDate(denuncia?.qnx?.ados_fech_prod)}
                                </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">COBERTURA:</h6>
                                <h6 className="subTitulo2 ms-2">{denuncia?.qnx?.ados_cobert}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                <h6 className="titulo2">PLAN:</h6>
                                <h6 className="subTitulo2 ms-2">
                                    {denuncia?.qnx?.ados_plan > 70
                                        ? `${denuncia?.qnx?.ados_plan} - Debito automatico`
                                        : denuncia?.qnx?.ados_plan}
                                </h6>
                            </div>
                        </div>
                        <div className="bordeNegro mb-3"></div>
                        <h5 className="titulo"> {denuncia.endosos.length > 1 ? "Endosos" : "No existen Endosos"}</h5>
                        {denuncia.endosos.length > 1 ? (
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <h6 className="titulo4">Pza / Prop</h6>
                                        </th>
                                        <th scope="col">
                                            <h6 className="titulo4">Fec. Ini</h6>
                                        </th>
                                        <th scope="col">
                                            <h6 className="titulo4">Fec. Fin</h6>
                                        </th>
                                        <th scope="col">
                                            <h6 className="titulo4">Causa</h6>
                                        </th>
                                        <th scope="col">
                                            <h6 className="titulo4">Usuario</h6>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {denuncia.endosos.map((d) => {
                                        return (
                                            <tr>
                                                <td className="subTitulo4">{d.ados_poliza}</td>
                                                <td className="subTitulo4">
                                                    {formatDate(d.ados_fech_ini)}
                                                </td>
                                                <td className="tsubTitulo4">
                                                    {formatDate(d.ados_fech_fin)}
                                                </td>
                                                <td className="tsubTitulo4">{d.ados_cod_end}</td>
                                                <td className="tsubTitulo4">{d.ados_w_user}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : null}
                        <div className="bordeNegro mb-3"></div>
                        <h5 className="titulo"> {denuncia.otrasDenuncias.porPatente.length > 1 || denuncia.otrasDenuncias.porPoliza.length > 1 ? "Stros" : "No existen Otras Denuncias"}</h5>
                        {denuncia.otrasDenuncias.porPatente.length > 1 || denuncia.otrasDenuncias.porPoliza.length > 1 ? (
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <h6 className="titulo4">Nro Stro</h6>
                                        </th>
                                        <th scope="col">
                                            <h6 className="titulo4">Asegurado</h6>
                                        </th>
                                        <th scope="col">
                                            <h6 className="titulo4">Patente</h6>
                                        </th>
                                        <th scope="col">
                                            <h6 className="titulo4">Pol/Pres</h6>
                                        </th>
                                        <th scope="col">
                                            <h6 className="titulo4">Fecha Stro</h6>
                                        </th>
                                        <th scope="col">
                                            <h6 className="titulo4">Inic. Vigencia</h6>
                                        </th>
                                        <th scope="col">
                                            <h6 className="titulo4">Fin Vigencia</h6>
                                        </th>
                                        <th scope="col">
                                            <h6 className="titulo4">Tipo Stro</h6>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {denuncia.otrasDenuncias.porPatente.filter((d) => d.id !== denuncia.id).map((d) => {
                                        return (
                                            <tr>
                                                <td className="subTitulo4">
                                                    {d?.nroStro?.toString().substring(3)}
                                                </td>
                                                <td className="subTitulo4">{d.nombreConductorAseg}</td>
                                                <td className="subTitulo4">{d.dom}</td>
                                                <td className="subTitulo4">{d.pza}</td>
                                                <td className="subTitulo4">
                                                    {formatDate(d.fechaStro)}
                                                </td>
                                                <td className="subTitulo4">
                                                {d?.qnx !== null ? formatDate(d.qnx?.ados_fech_ini) : '---'}
                                                </td>
                                                <td className="subTitulo4">
                                                    {d.qnx !== null ? formatDate(d.qnx?.ados_fech_fin) : '---'}
                                                </td>
                                                <td className="subTitulo4">{d.catStro}</td>
                                            </tr>
                                        );
                                    })}
                                    {denuncia.otrasDenuncias.porPoliza.filter((d) => d.id !== denuncia.id).map((d) => {
                                        return (
                                            <tr>
                                                <td className="subTitulo4">
                                                    {d?.nroStro?.toString().substring(3)}
                                                </td>
                                                <td className="subTitulo4">{d.nombreConductorAseg}</td>
                                                <td className="subTitulo4">{d.dom}</td>
                                                <td className="subTitulo4">{d.pza}</td>
                                                <td className="subTitulo4">
                                                    {formatDate(d.fechaStro)}
                                                </td>
                                                <td className="subTitulo4">
                                                    {d?.qnx !== null ? formatDate(d.qnx?.ados_fech_ini) : '---'}
                                                </td>
                                                <td className="subTitulo4">
                                                    {d.qnx !== null ? formatDate(d.qnx?.ados_fech_fin) : '---'}
                                                </td>
                                                <td className="subTitulo4">{d.catStro}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : null}
                    </section>
                </>
            ) : (
                <div className="center">
                    <div class="spinner-border text-success center" role="status">
                        <span class="visually-hidden center">Loading...</span>
                    </div>
                </div>
            )}
        </>
    );
}
