import { useEffect, useState } from "react";
import "../../pages/cargaTabla.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { checkContext } from "../../context/Checka";
export default function ComponenteTablaCarga({ denuncia, estados, amp }) {
    const link = { textDecoration: "none" };
    const { checka, setChecka } = useContext(checkContext);
    const [check, setChecked] = useState(false);
    function handleChange(e) {
        setChecked(e.target.checked);
        if (e.target.checked) {
            if (!checka.includes(denuncia.id)) {
                setChecka((prevChecka) => [...prevChecka, denuncia.id]);
            }
        } else {
            if (checka.includes(denuncia.id)) {
                setChecka((prevChecka) =>
                    prevChecka.filter((item) => item !== denuncia.id)
                );
            }
        }
    }
    function formatDate(dateString) {
        const [year, month, day] = dateString.split("-");
        return `${day}-${month}-${year}`;
    }
    const handleClick = (event, id) => {
        event.preventDefault();
        if (event.ctrlKey || event.metaKey) {
            window.open(`/#/${id}`, "_blank");
        } else {
            event.preventDefault();
            window.location.href = event.currentTarget.href;
        }
    };
    const optionsPrioridad = [{ label: '', value: 0 }, { label: 'BAJA', value: 1 }, { label: 'MEDIA', value: 2 }, { label: 'ALTA', value: 3 }]
    const prioridadSeleccionado = optionsPrioridad.find(p => p.value === Number(denuncia.prioridad));
    const ampS = amp.filter(a => a.denuncia === Number(denuncia.entryId) || (a.stro === Number(denuncia.nroStro) && a.stro !== 0));
    return (
        <tr className="" style={{height: '50px'}}>
            <th scope="col" className="ps-0 pe-0 align-middle">
                <div className="d-flex">
                    <input
                        class="form-check-input ms-1 m-0"
                        type="checkbox"
                        onChange={handleChange}
                        checked={checka.includes(denuncia.id) ? true : false}
                    />
                    <h4 className="ms-1 m-0 tablaCargaBodyText1 text-center">
                        {denuncia?.nroStro?.toString().substring(3)}
                    </h4>
                </div>
            </th>
            <th scope="col" className="ps-0 pe-0 align-middle">
                <h4 className="m-0 tablaCargaBodyText1">
                    {denuncia?.entryId}
                </h4>
            </th>
            <th scope="col" className="ps-0 pe-0 align-middle">
                <h4 className="m-0 tablaCargaBodyText1">{denuncia?.pza}</h4>
            </th>
            <th scope="col" className="ps-0 pe-0 align-middle">
                <div class="m-0 tablaCargaBodyText2">{denuncia?.dom}</div>
            </th>
            <th scope="col" className="ps-0 pe-0 align-middle">
                <h4 class=" m-0 tablaCargaBodyText2">
                    {formatDate(denuncia?.fechaDenuncia)}
                </h4>
            </th>
            <th scope="col" className="ps-0 pe-0 align-middle">
                <h4 class="m-0 tablaCargaBodyText2">{formatDate(denuncia?.fechaStro)}</h4>
            </th>
            <th scope="col" className="ps-0 pe-0 align-middle">
                <h4 class=" m-0 tablaCargaBodyText2">
                    {denuncia?.recepcionRecChoques
                        ? formatDate(denuncia?.recepcionRecChoques)
                        : null}
                </h4>
            </th>
            <th scope="col" className="ps-0 pe-0 align-middle">
                <h4 class="m-0 tablaCargaBodyText2">
                    {denuncia?.fechaVencimiento
                        ? formatDate(denuncia?.fechaVencimiento)
                        : null}
                </h4>
            </th>
            <th scope="col" className="ps-0 pe-0 align-middle">
                <h4 class="m-0 tablaCargaBodyText2">{prioridadSeleccionado.label}</h4>
            </th>
            <th scope="col" className="ps-0 pe-0 align-middle">
                <div class="tablaCargaBodyText2 m-0">
                    <div className="d-flex align-items-center">
                        <div
                            className={
                                denuncia.empleadoAsignado ? "circleVioleta me-1 center" : ""
                            }
                        ></div>
                        {denuncia.empleadoAsignado}
                    </div>
                </div>
            </th>
            <th scope="col" className="ps-0 pe-0 align-middle">
                <div class="tablaCargaBodyText2 m-0">
                    <div className="d-flex align-items-center">
                        <div
                            className={
                                denuncia.estadoNuevo === 203
                                    ? "circleRojo me-1"
                                    : denuncia.estadoNuevo === 200
                                        ? "circleGris me-1"
                                        : "circleVerde me-1"
                            }
                        ></div>
                        {estados.find((e) => e.id === Number(denuncia.estadoNuevo))?.abreviacion}
                    </div>
                </div>
            </th>
            <th scope="col" className="ps-0 pe-0 align-middle">
                <div class="tablaCargaBodyText2 m-0">{denuncia?.catStro}</div>
            </th>
            <th scope="col d-flex" className="ps-0 pe-0 align-middle">
                <Link
                    to={`/${denuncia.id}`}
                    onClick={(event) => handleClick(event, denuncia.id)}
                    className="tablaCargaBodyText2 text-primary m-0"
                >
                    INGRESAR
                </Link>
                {ampS.length > 0 ? <div style={{ position: "relative", display: "inline-block" }} className="ms-1">
                    <i className="bi bi-file-text-fill" style={{color: '#42C3BA'}}></i>
                    {ampS?.length > 0 && (
                        <span
                            className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning"
                            style={{ zIndex: 1 }}
                        >
                            {ampS.length}
                        </span>
                    )}
                </div> : null}
            </th>
        </tr>
    );
}
