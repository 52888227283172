import { useCallback, useEffect } from "react";
import "./stro.css";
import axios from "axios";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import './choquesStro.css'
import { useCookies } from 'react-cookie';
import MensajesCronologiaCarga from "../components/carga/MensajesCronologiaMensajes";
import Select from "react-select";
import ModuloDerEstadoChoquesTransitorio2 from "../components/choques/ModuloChoquesTransitorio2";
import ModuloDerEstadoChoquesTransitorioChat from "../components/choques/ModuloDerChoquesTransitorioChat";
import ModuloActualizacionStro from "../components/stro/ModuloActualizacionStro";
import Header from '../components/general/Header'
import ModuloIzqChoques from "../components/choques/ModuloIzqChoques";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import axiosRetry from 'axios-retry';
import ModuloActualizacionStro1 from "../components/stro/ModuloActualizacionStro1";
import ModuloEstadosStro from "../components/stro/ModuloEstadosStro";
axiosRetry(axios, { retries: 3 });
export default function StroChoque() {
    const id = useParams().id;
    useEffect(() => {
        localStorage.setItem('id', id);
    }, []);
    const [cookies] = useCookies(["user"], ['asignado']);
    const asignado = cookies.asignado
    const [denuncia, setDenuncia] = useState([]);
    const [cargando, setCargando] = useState(false)
    const [frente, setFrente] = useState({})
    const [sicobe, setSicobe] = useState([])
    const [pagosAnteriores1, setPagosAnteriores1] = useState([])
    const [cobert, setCobert] = useState('')
    const [carga2, setCarga2] = useState(false)
    const [opacity, setOpacity] = useState(0)
    const [estados, setEstados] = useState([])
    const [estadosCodigos, setEstadosCodigos] = useState([])
    const [carga3, setCarga3] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const resDenuncia = await axios.get(`https://back.agsseguros.online/api/choque/pruebaChoquesSinNada/${id}`);
                setDenuncia(resDenuncia.data);
                setCargando(true)
                const resEstados = await axios.get(`https://back.agsseguros.online/api/estados/estadosContractuales/${id}`);
                setEstados(resEstados.data);
                const estadosCod = await axios.get(`https://back.agsseguros.online/api/estados/codigosParaEstado/${id}`)
                setEstadosCodigos(estadosCod.data)
                setCarga3(true);
                const resSicobe = await axios.get(`https://back.agsseguros.online/api/tieneCobertura/${id}`);
                setSicobe(resSicobe.data);
                // pagos que existen
                const pagos = resSicobe.data.pagos;
                // plan de pagos
                const arrayFechaLimite = resSicobe.data.fechaLimiteWe
                const fechaStro = new Date(resSicobe.data.fechaStro);
                const pagosAnteriores = [];
                // por cada elemento del array fechaLimite (plan de pagos), si la fecha del stro fue mayor que la fecha limite, se pushea dicho elemento
                for (let i = 0; i < arrayFechaLimite.length; i++) {
                    if (new Date(arrayFechaLimite[i]) < fechaStro) {
                        pagosAnteriores.push([i]);
                    }
                }
                setPagosAnteriores1(pagosAnteriores);
                const totalImporteFechaStro = pagos.slice(0, pagosAnteriores1.length).map(item => parseFloat(item?.pga_importe) || 0).reduce((acc, importe) => acc + importe, 0);
                const totalImporteEsperado = (parseFloat(pagos[0]?.pga_importe) || 0) * pagosAnteriores1.length;
                if (pagos.length === 0) {
                    setCobert('COBERTURA')
                } else if (pagos[0]?.pga_mp === 'TA') {
                    setCobert('COBERTURA');
                } else if (totalImporteFechaStro !== totalImporteEsperado) {
                    setCobert('EN REVISION');
                } else if (pagosAnteriores1.length > 0) {
                    const ultimoPagoAnterior = pagos[pagosAnteriores1.length - 1];
                    const pgaFechaAlta = new Date(ultimoPagoAnterior.pga_fliqags);
                    const fechaLimite = new Date(arrayFechaLimite[pagosAnteriores1.length - 1]);
                    if (pgaFechaAlta <= fechaLimite) {
                        setCobert('COBERTURA');
                    } else if (pgaFechaAlta > fechaLimite) {
                        if (fechaStro >= pgaFechaAlta) {
                            setCobert('COBERTURA');
                        } else {
                            setCobert('EN REVISION');
                        }
                    }
                } else {
                    setCobert('EN REVISION')
                }
                const resFrente = await axios.get(`https://back.agsseguros.online/api/frente/${id}`);
                setFrente(resFrente.data);
                setCarga2(true);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
        fetchData();
    }, [id]);
    const esAsignado = asignado?.findIndex(e => e.id === denuncia?.id)
    const asignadoMas1 = asignado[esAsignado + 1]
    const asignadoMenos1 = asignado[esAsignado - 1]
    const today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    const year = today.getFullYear();
    day = (day < 10) ? '0' + day : day;
    month = (month < 10) ? '0' + month : month;
    const formattedDate = day + '/' + month + '/' + year;
    function getCurrentTime() {
        const now = new Date();
        let hour = now.getHours();
        let minute = now.getMinutes();
        minute = (minute < 10) ? '0' + minute : minute;
        return hour + ':' + minute;
    }
    const currentTime = getCurrentTime();
    const [mensaje, setMensaje] = useState({ obs: null, motivo: null })
    const motivoMensajeInput = [{ label: "AMP", value: "AMP" }, { label: "OTROS", value: "OTROS" }, {label: 'RR', value: 'RR'}, {label: 'CTZ', value: 'CTZ'}, {label: 'ASIG', value: 'ASIG'}, {label: 'RCIA', value: 'RCIA'}, {label: 'IN/REC', value: 'IN/REC'}, {label: 'TER', value: 'TER'}, {label: 'CER', value: 'CER'}, {label: 'ARCH', value: 'ARCH'}, {label: 'AUD', value: 'AUD'}, {label: 'DDA', value: 'DDA'}, {label: 'INF', value: 'INF'}]
    const comentarioUpdate = useCallback(async (e) => {
        e.preventDefault()
        setOpacity(1)
        await axios.post(`https://back.agsseguros.online/api/cronologia`,
            {
                id: denuncia.id,
                desc: '-',
                obs: mensaje.obs,
                jerarquia: cookies.user.depto === 'cargas' ? 'CRG' : 'DEN',
                motivo: mensaje.motivo,
                autor: cookies.user.usuario,
                fecha: formattedDate,
                hora: currentTime
            }
        ).then((response) => { if (response) { window.location.reload() } })
    }, [mensaje, denuncia, cookies, currentTime, formattedDate]);
    const colourStyles = {
        menuList: styles => ({
            ...styles,
            background: 'white'
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
                ? '#42C3BA'
                : isSelected
                    ? '#42C3BA'
                    : undefined,
            border: isFocused
                ? '#42C3BA 1px solid'
                : isSelected
                    ? '#42C3BA 1px solid'
                    : undefined,
            zIndex: 1
        }),
        menu: base => ({
            ...base,
            zIndex: 100
        })
    }
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    const qnxInic = formatDate(denuncia?.qnx?.ados_fech_ini);
    const qnxFin = formatDate(denuncia?.qnx?.ados_fech_fin);
    const fechaStro = formatDate(denuncia?.fechaStro);
    return (
        <>
            <Header denuncia={denuncia} asignadoMas1={asignadoMas1} asignadoMenos1={asignadoMenos1} />
            <main>
                {opacity === 1 ? <div className="SpinnerInTheMiddle"><div class="spinner-border text-success center" role="status"><span class="visually-hidden center">Loading...</span></div></div> : <></>}
                {cargando ?
                    <section className="rowMain">
                        <section className="columnMain">
                            <ModuloIzqChoques denuncia={denuncia} frente={frente} sicobe={sicobe} pagosAnteriores={pagosAnteriores1} cobert={cobert} carga2={carga2}/>
                        </section>
                        <section className="columnMain">
                            {/*<ModuloActualizacionStro denuncia={denuncia}></ModuloActualizacionStro>
                            <ModuloDerEstadoChoquesTransitorioChat mensajes={denuncia?.cronologia.slice(-3)} denuncia={denuncia} />
                            <ModuloDerEstadoChoquesTransitorio2 stro={denuncia} fechaStro={fechaStro} qnxInic={qnxInic} qnxFin={qnxFin} />*/}
                            <ModuloActualizacionStro1 denuncia={denuncia} carga3={carga3} estadosValores={estados} estadosCodigos={estadosCodigos}></ModuloActualizacionStro1>
                            <ModuloEstadosStro denuncia={denuncia} carga3={carga3} estados={estados}></ModuloEstadosStro>
                            <ModuloDerEstadoChoquesTransitorio2 stro={denuncia} fechaStro={fechaStro} qnxInic={qnxInic} qnxFin={qnxFin} />
                        </section>
                    </section>
                    :
                    <div className="center pt-5"><div class="spinner-border text-success center" role="status"><span class="visually-hidden center">Loading...</span></div></div>}
                {cargando ?
                    <section className="me-4 ms-4 pb-5">
                        <div className="cronologiaStroContainer pe-4 ps-4">
                            <h2 className="tituloCronologiaStro ps-3">CRONOLOGIA DEL STRO</h2>
                            {denuncia?.cronologia.map((m) => (
                                <MensajesCronologiaCarga mensaje={m} />
                            ))}
                            <div className="d-flex mt-5">
                                <textarea className="mensajeCronoliaStro ps-3 pt-1" value={mensaje.obs}
                                    onChange={(e) =>
                                        setMensaje((prevState) => ({
                                            ...prevState,
                                            obs: e.target.value,
                                        }))} />
                                <div className="sendContainer">
                                    <div className="sendMessageBtnContainer center">
                                        <button className="btnNO text-white" onClick={(e) => comentarioUpdate(e)} disabled={mensaje.motivo === null ? true : false}>
                                            <i class="bi bi-send"></i>
                                        </button>
                                    </div>
                                    <div className="sendMessageUserContainer mt-2">
                                        <Select
                                            styles={colourStyles}
                                            options={motivoMensajeInput} className='w-100'
                                            placeholder=''
                                            onChange={(newValue) => setMensaje((prevState) => ({
                                                ...prevState,
                                                motivo: newValue.value,
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <></>}
            </main>
        </>
    );
}
