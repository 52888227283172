import './choques.css'
import VerTodoDenuncia from "../carga/VerTodoDenuncia";
import ActualizarDenuncia from '../carga/ActualizarDenuncia'
import sharepoint from '../../images/sharepoint.jpeg'
import sharepointRojo from '../../images/shareRojo.png'
import { useCallback, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import MenuIzquierdo from '../general/MenuIzquierdo'
import { useParams, Link } from 'react-router-dom';
import { PDFDownloadLink } from "@react-pdf/renderer";
import FrentePdf from "../pdf/Frente";
import PDF from "../pdf/Sicobe";
export default function ModuloIzqChoques({ denuncia, frente, sicobe, pagosAnteriores, cobert, carga2 }) {
    const [cookies] = useCookies(["user"], ['asignado']);
    const handleLinkClick = (event) => {
        if (!denuncia.link.startsWith('http')) {
            event.preventDefault();
            window.open(`https://${denuncia.link}`, '_blank');
        }
    };
    const logo = { height: '38px', width: '38px' }
    const [link1, setLink1] = useState({ link: denuncia?.link });
    const linkUpdate = useCallback(async (e) => {
        e.preventDefault()
        await axios.post(`https://back.agsseguros.online/api/denuncia/linkSharepoint`,
            {
                id: denuncia.id,
                link: link1.link,
            }
        ).then((response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [denuncia, link1]);
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    const [botonOff, setBotonOff] = useState(0)
    const openInNewTab = (url, e) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <>
            <section className='moduloIzqChoques pe-4 ps-4'>
                <div className="d-flex justify-content-between align-items-center mt-2 pe-4 pt-1">
                    <h2 className="tituloStroTabla2 m-0 p-0">Denuncia Administrativa</h2>
                    <div className='d-flex w-100 ms-5'>
                        {carga2 ? <PDFDownloadLink document={<FrentePdf denuncia={frente} />} fileName={`frenteStro${denuncia?.nroStro?.toString().substring(3)}.pdf`}>
                            {({ loading, url, error, blob }) =>
                                <div className='center me-2'><button id="botonFrente" className='btn btn-primary btn-sm'>Descargar Frente</button></div>
                            }
                        </PDFDownloadLink> : null}
                        {carga2 && denuncia.nroStro ? <PDFDownloadLink document={<PDF sicobe={sicobe} pagosAnteriores={pagosAnteriores} cobert={cobert} />} fileName={`sicobeStro${denuncia?.nroStro?.toString().substring(3)}.pdf`}>
                            {({ loading, url, error, blob }) => <div className='center ms-2'><button id="botonSicobe" className='btn btn-primary btn-sm'>Descargar Sicobe</button></div>}
                        </PDFDownloadLink> : null}
                    </div>
                    <div className="d-flex center">
                        <div className='btnVerTodo1Stro text-white center'><button class="btnNO center text-white" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight1" aria-controls="offcanvasRight1">Ver Todo</button></div>
                        <div class="offcanvas offcanvas-end w-75" tabindex="-1" id="offcanvasRight1" aria-labelledby="offcanvasRightLabel1">
                            <div class="offcanvas-header">
                                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div class="offcanvas-body pe-5 ps-5">
                                <VerTodoDenuncia />
                            </div>
                        </div>
                        <div className='btnVerTodo3Stro text-white center'><button class="btnNO center text-white" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Editar</button></div>
                        <div class="offcanvas offcanvas-end w-75" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                            <div class="offcanvas-header">
                                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div class="offcanvas-body pe-5 ps-5">
                                <ActualizarDenuncia />
                            </div>
                        </div>
                        {denuncia.link !== '' ?
                            <div className='center ms-3 btnSharepoint'>
                                <a href={`${denuncia.link}`} target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>
                                    <img src={sharepoint} alt="" style={logo} />
                                </a>
                            </div> :
                            <div className='center ms-3 btnSharepoint'>
                                <button className='btnNO' data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <img src={sharepointRojo} alt="" style={logo} />
                                </button>
                            </div>}
                        <div class="modal fade mt-5" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Link Sharepoint</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <input type="text" className='w-100 ps-3' onChange={(e) =>
                                            setLink1((prevState) => ({
                                                ...prevState,
                                                link: e.target.value,
                                            }))} />
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                        <button type="button" class="btn btn-primary" onClick={(e) => linkUpdate(e)}>Actualizar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {denuncia.nroStro !== null ?
                            <div className='ms-3'>
                                <a href={`https://ags.webmanager.com.ar/LinkAuth/${cookies.user.digi}/QWdzbmV0MDgxMQ==/${denuncia.nroStro.toString().substring(3)}`} target="_blank"><i class="bi bi-file-earmark-text" style={{ fontSize: '38px', color: '#42C3BA' }}></i></a>
                            </div> : <></>}
                    </div>
                </div>
                <div className="d-flex align-items-center infoStroElemento">
                    <label className="labelStro">Fecha Stro</label>
                    <div className="inputStroVigencia d-flex align-items-center text-danger">
                        <p className="m-0 textoCarga">{formatDate(denuncia?.fechaStro)}</p>
                    </div>
                </div>
                <div className="d-flex align-items-center infoStroElemento">
                    <label className="labelStro">Poliza</label>
                    <div className="inputStroVigencia d-flex align-items-center ps-3">
                        <Link onClick={(e) => openInNewTab(`https://www.agsnet.com.ar/muestro-polizas2.php?prop=${denuncia?.qnx?.ados_nro_cc}&rtn=consulta-polizas`)}>{denuncia?.qnx?.ados_poliza}</Link>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="d-flex align-items-center infoStroElemento col">
                        <label className="labelStro">Productor</label>
                        <div className="inputStroVigenciaCol6 d-flex align-items-center text-danger">
                        <p className="m-0 textoCarga">{denuncia?.productor?.pro_productor} - {denuncia?.productor?.pro_raz_social}</p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center infoStroElemento col">
                        <label className="labelStro">Direccion Aseg</label>
                        <div className="inputStroVigenciaCol6 d-flex align-items-center text-danger">
                            <p className="m-0 textoCarga">{denuncia?.qnx?.ados_dir_aseg}</p>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="d-flex align-items-center infoStroElemento col">
                        <label className="labelStro">Nombre Aseg</label>
                        <div className="inputStroVigenciaCol6 d-flex align-items-center text-danger">
                        <p className="m-0 textoCarga">{denuncia?.qnx?.ados_nom_aseg}</p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center infoStroElemento col">
                        <label className="labelStro">Localidad Aseg</label>
                        <div className="inputStroVigenciaCol6 d-flex align-items-center text-danger">
                            <p className="m-0 textoCarga">{denuncia?.qnx?.ados_loc_aseg}</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center infoStroElemento">
                    <label className="labelStro">Nombre Aseg</label>
                    <div className="inputStroVigencia d-flex align-items-center">
                        <p className="m-0 textoCarga">{denuncia?.qnx?.ados_nom_aseg}</p>
                    </div>
                </div>
                <div className="d-flex align-items-center infoStroElemento">
                    <label className="labelStro">Patente</label>
                    <div className="inputStroVigencia d-flex align-items-center">
                        <p className="m-0 textoCarga">{denuncia?.qnx?.ados_patente}</p>
                    </div>
                </div>
                <div className="d-flex align-items-center infoStroElemento">
                    <label className="labelStro">Tipo Vehiculo</label>
                    <div className="inputStroVigencia d-flex align-items-center text-danger">
                        <p className="m-0 textoCarga">{denuncia?.tipoAseg}</p>
                    </div>
                </div>
                <div className="d-flex align-items-center infoStroElemento">
                    <label className="labelStro">Marca/Modelo</label>
                    <div className="inputStroVigencia d-flex align-items-center textoCarga">
                        {`${denuncia?.qnx?.ados_vehiculo} - ${denuncia?.qnx?.ados_modelo}`}
                    </div>
                </div>
                <div className="d-flex align-items-center infoStroElemento">
                    <label className="labelStro">Stros Ant</label>
                    <div className="inputStroVigencia d-flex align-items-center textoCarga">{denuncia.mismaPatente.length > 1 ? 'SI' : 'NO'}</div>
                </div>
                <h2 className="tituloStroTabla mt-3">DATOS 3RO</h2>
                <div className="row infoStroElemento mt-3">
                    <div className="col-6 d-flex align-items-center infoStroElemento">
                        <label className="labelStro">Patente</label>
                        <div className="inputStroVigenciaCol6 d-flex align-items-center textoCarga">{denuncia?.terceroViejo ? denuncia.terceroViejo.patente : null}</div>
                    </div>
                    <div className="col-6 d-flex align-items-center infoStroElemento">
                        <label className="labelStro">Nombre Tercero</label>
                        <div className="inputStroVigenciaCol6 d-flex align-items-center textoCarga">{denuncia.conductorTercero1 ? denuncia.conductorTercero1 : (denuncia?.terceroViejo ? denuncia.terceroViejo.nombre : null)}</div>
                    </div>
                </div>
                <div className="row infoStroElemento mt-3">
                    <div className="col-6 d-flex align-items-center infoStroElemento">
                        <label className="labelStro">Fecha Rec Viejo</label>
                        <div className="inputStroVigenciaCol6 d-flex align-items-center textoCarga">{denuncia?.terceroViejo ? denuncia.terceroViejo.fechaRec : null}</div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className='col center'><div className='btnVerTodo2Stro center'><button onClick={(e) => setBotonOff(1)} className='btnNO text-white center'>Frente</button></div></div>
                    <div className='col center'><div className='btnVerTodo2Stro center'><button onClick={(e) => setBotonOff(2)} className='btnNO text-white center'>Pagos</button></div></div>
                    <div className='col center'><div className='btnVerTodo2Stro center'><button onClick={(e) => setBotonOff(3)} className='btnNO text-white center'>Siniestros</button></div></div>
                    <div className='col center'><div className='btnVerTodo2Stro center'><button onClick={(e) => setBotonOff(4)} className='btnNO text-white center'>Endosos</button></div></div>
                </div>
            </section>
            {botonOff !== 0 ? <MenuIzquierdo botonOff={botonOff} setBotonOff={setBotonOff} denuncia={denuncia} /> : <></>}
        </>
    );
}
