import { useCallback, useState } from "react";
import "./modulosGeneralesCss.css";
import { useEffect } from "react";
import axios from "axios";
export default function ModuloEstadosStro({ denuncia, estados, carga3 }) {
    const [estado1, setEstado1] = useState({});
    useEffect(() => {
        if (estados?.valores1) {
            setEstado1(estados.valores1);
        }
    }, [estados?.valores1]);
    const handleInputChange = (ev, fieldName) => {
        const { type, checked, value } = ev.target;
        setEstado1(prevState => ({
            ...prevState,
            [fieldName]: type === 'checkbox' ? checked : value  // Update state based on input type
        }));
    };
    const [estado2, setEstado2] = useState({});
    useEffect(() => {
        if (estados?.valores2) {
            setEstado2(estados.valores2);
        }
    }, [estados?.valores2]);
    const handleInputChange2 = (ev, fieldName) => {
        const { type, checked, value } = ev.target;
        setEstado2(prevState => ({
            ...prevState,
            [fieldName]: type === 'checkbox' ? checked : value
        }));
    };
    const obtenerFechaHoy = () => {
        const hoy = new Date();
        const dia = String(hoy.getDate()).padStart(2, '0');
        const mes = String(hoy.getMonth() + 1).padStart(2, '0');
        const año = hoy.getFullYear();
        const horas = String(hoy.getHours()).padStart(2, '0');
        const minutos = String(hoy.getMinutes()).padStart(2, '0');
        const segundos = String(hoy.getSeconds()).padStart(2, '0');
        return `${dia}/${mes}/${año} ${horas}:${minutos}:${segundos}`;
    };
    const actualizarValores = useCallback(async (e, estado, camposAct) => {
        e.preventDefault();
        const primerCampoNombre = camposAct;
        const estadoActualizado = { ...estado, [primerCampoNombre]: obtenerFechaHoy() };
        await axios.post(`https://back.agsseguros.online/api/estados/actualizarValoresEstado`, { id: denuncia.id, valores: estadoActualizado })
            .then(() => {
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            })
            .catch(error => {
                console.error('Error al actualizar:', error);
            });
    }, [denuncia,]);
    return (
        <>
            {estados.estadoNuevo !== 200 ?
                <section className="moduloEstadosContenedor">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h2 className=" tituloModuloEstados">{estados?.estado1?.nombre}</h2>
                        <h2 className="modificacionModuloEstados"><i class="bi bi-calendar-week me-1"></i> Ult Mod: {estados?.camposEstado1?.length > 0 ? estados?.valores1[estados.camposEstado1[0].nombre] : null}</h2>
                    </div>
                    <form onSubmit={(e) => actualizarValores(e, estado1, estados?.camposEstado1[0]?.nombre)}>
                        <div className="containerEstados">
                            {estados?.camposEstado1?.slice(1).map((e, index) => (
                                <div className="grid-item" key={index}>
                                    <label htmlFor="" className="labelEstados">{e.label.toUpperCase()}</label>
                                    {e.type === 'select' ? (
                                        <select
                                        className="form-select form-select-sm rounded-pill"
                                        value={estado1[e.nombre] || ""}
                                        onChange={(ev) => handleInputChange(ev, e.nombre)}
                                    >
                                        {/* Verificar si el campo es null o vacío y mostrar una opción deshabilitada */}
                                        {(estado1[e.nombre] === null || estado1[e.nombre] === "") && (
                                            <option value="" disabled>Selecciona una opción</option>
                                        )}
                                    
                                        {/* Opciones del campo */}
                                        {e.opciones.split(',').map((opcion, opcionIndex) => (
                                            <option key={opcionIndex} value={opcion.trim()}>
                                                {opcion.trim()}
                                            </option>
                                        ))}
                                    </select>
                                    ) : (
                                        <input
                                            type={e.type}
                                            className={e.type === 'checkbox' ? 'form-check-input rounded-pill' : 'form-control form-control-sm rounded-pill'}
                                            style={
                                                e.type === 'date'
                                                    ? { maxWidth: '180px' }
                                                    : e.type === 'number'
                                                        ? { maxWidth: '140px' }
                                                        : null
                                            }
                                            value={e.type !== 'checkbox' ? estado1[e.nombre] : undefined}
                                            checked={e.type === 'checkbox' ? estado1[e.nombre] : undefined}
                                            onChange={(ev) => handleInputChange(ev, e.nombre)}
                                        />
                                    )}

                                </div>
                            ))}
                        </div>
                        <div className="d-flex justify-content-end align-items-center mt-3 mb-4"><button type="submit" className="botonActualizarEstados">Actualizar</button></div>
                    </form>
                </section> : <></>}
            {estados?.estado2 !== null && denuncia?.catStro && denuncia?.catStro.length === 6 ?
                <section className="moduloEstadosContenedor">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h2 className=" tituloModuloEstados">{estados?.estado22?.nombre}</h2>
                        <h2 className="modificacionModuloEstados"><i class="bi bi-calendar-week me-1"></i> Ult Mod: {estados?.camposEstado2?.length > 0 ? estados?.valores2[estados.camposEstado2[0].nombre] : null}</h2>
                    </div>
                    <form onSubmit={(e) => actualizarValores(e, estado2, estados?.camposEstado2[0]?.nombre)}>
                        <div className="containerEstados">
                            {estados?.camposEstado2?.slice(1).map((e, index) => (
                                <div className="grid-item" key={index}>
                                    <label htmlFor="" className="labelEstados">{e.label.toUpperCase()}</label>
                                    {
                                        e.type === 'select' ? (
                                            <select
                                                className="form-select form-select-sm rounded-pill"
                                                value={estado2[e.nombre]}
                                                onChange={(ev) => handleInputChange2(ev, e.nombre)}
                                            >
                                                {e.opciones.split(',').map((opcion, opcionIndex) => (
                                                    <option key={opcionIndex} value={opcion.trim()}>
                                                        {opcion.trim()}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <input
                                                type={e.type}
                                                className={e.type === 'checkbox' ? 'form-check-input rounded-pill' : 'form-control form-control-sm rounded-pill'}
                                                style={
                                                    e.type === 'date'
                                                        ? { maxWidth: '180px' }
                                                        : e.type === 'number'
                                                            ? { maxWidth: '140px' }
                                                            : null
                                                }
                                                value={e.type !== 'checkbox' ? estado2[e.nombre] : undefined}
                                                checked={e.type === 'checkbox' ? estado2[e.nombre] : undefined}
                                                onChange={(ev) => handleInputChange2(ev, e.nombre)}
                                            />
                                        )
                                    }

                                </div>
                            ))}
                        </div>
                        <div className="d-flex justify-content-end align-items-center mt-3 mb-4"><button type="submit" className="botonActualizarEstados">Actualizar</button></div>
                    </form>
                </section> : <></>}
        </>
    );
}
