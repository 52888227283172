import { Link, useParams } from 'react-router-dom';
import './carga.css'
import { useEffect, useState } from 'react';
import axios from 'axios';
export default function Endosos({}) {
    const id = useParams().id;
    const [endosos, setEndosos] = useState([])
    const [cargando, setCargando] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const resDenuncia = await axios.get(`https://back.agsseguros.online/api/endosos/${id}`);
                setEndosos(resDenuncia.data);
                setCargando(true)
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
        fetchData();
    }, [id]);
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    const motivo = [{nombre: 'Cambio de Vehiculo', id: 1}, {nombre: 'Modificacion de Cobertura', id: 2}, {nombre: 'Extension de Cob. a paises de Sudamerica', id: 3}, {nombre: 'Calculo Correcto (Positivo)', id: 4}, {nombre: 'Rehabilitacion de poliza', id: 5}, {nombre: 'Correcion de Suma asegurada', id: 6}, {nombre: 'Ajuste de recargos (Positivo)', id: 7}, {nombre: 'Extension de Vigencia', id: 8}, {nombre: 'Inclusion de Accesorios', id: 9}, {nombre: 'Servicios Adicionales', id: 10}, {nombre: 'Cambio de Categoria de I.V.A', id: 11}, {nombre: 'Cambio de Uso', id: 12}, {nombre: 'Endoso sin movimiento', id: 13}, {nombre: 'Inclusion de Grua', id: 14}, {nombre: '', id: 0}]
    const openInNewTab = (url,e ) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <>
            {cargando ? <table class="tablaOtrasDenunciasContainer table">
            <thead>
                    <tr>
                        <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Pza</h6></th>
                        <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Fec. Ini</h6></th>
                        <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Fec. Fin</h6></th>
                        <th scope="col"><h6 className='textoHeaderTablaOtrasDenuncias'>Causa</h6></th>
                        <th scope='col'><h6 className='textoHeaderTablaOtrasDenuncias'>Usuario</h6></th>
                        <th scope='col'><h6 className='textoHeaderTablaOtrasDenuncias'>Fecha Emi</h6></th>
                        <th scope='col'><h6 className='textoHeaderTablaOtrasDenuncias'>Prop</h6></th>
                    </tr>
                </thead>
                <tbody>
                    {endosos.endosos.length > 0 ? endosos.endosos.map(d => {
                        return <tr>
                            <td className='textoBodyTablaOtrasDenuncias'>{d.ados_poliza}</td>
                            <td className='textoBodyTablaOtrasDenuncias'>{formatDate(d.ados_fech_ini)}</td>
                            <td className='textoBodyTablaOtrasDenuncias'>{formatDate(d.ados_fech_fin)}</td>
                            <td className='textoBodyTablaOtrasDenuncias'>{motivo.find(m => m.id === d.ados_cod_end) ? motivo.find(m => m.id === d.ados_cod_end).nombre : null}</td>
                            <td className='textoBodyTablaOtrasDenuncias'>{d.ados_w_user}</td>
                            <td className='textoBodyTablaOtrasDenuncias'>{formatDate(d.ados_fech_emi)}</td>
                            <td className='textoBodyTablaOtrasDenuncias'><Link onClick={(e) => openInNewTab(`https://www.agsnet.com.ar/muestro-polizas2.php?prop=${d.ados_nro_cc}&rtn=consulta-polizas`)}>{d.ados_nro_cc}</Link></td>
                        </tr>
                    }) : <></>}
                </tbody>
        </table> : <div className="center"><div class="spinner-border text-success center" role="status"><span class="visually-hidden center">Loading...</span></div></div>}
        </>
    );
}