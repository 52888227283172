import { useCallback, useState } from "react";
import "./ampliaciones.css";
import { Link } from "react-router-dom";
import axios from "axios";
export default function AmpliacionesTabla({ ampliacion }) {
    function formatDate(dateString) {
        const [year, month, day] = dateString.split("-");
        return `${day}/${month}/${year}`;
    }
    const ampliacionVista = useCallback(
        async (e, estado, camposAct) => {
            e.preventDefault();
            await axios
                .post(`https://back.agsseguros.online/api/ampliaciones/visto`, {
                    id: ampliacion.id,
                })
                .then(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                })
                .catch((error) => {
                    console.error("Error al actualizar:", error);
                });
        },
        [ampliacion]
    );
    return (
        <>
            <tr className="">
                <th>
                    <div className="ampliacionesTexto">{ampliacion?.tipo}</div>
                </th>
                <th>
                    <div className="ampliacionesTexto">
                        {ampliacion?.idRelacion ? (
                            <Link
                                to={`/#/${ampliacion.idRelacion}`}
                                target="_blank"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(
                                        `/#/${ampliacion.idRelacion}`,
                                        "_blank",
                                        "noopener,noreferrer"
                                    );
                                }}
                            >
                                {ampliacion?.denuncia}
                            </Link>
                        ) : (
                            ampliacion?.denuncia
                        )}
                    </div>
                </th>

                <th>
                    <div className="ampliacionesTexto">{ampliacion?.poliza}</div>
                </th>
                <th>
                    <div className="ampliacionesTexto">
                        {ampliacion?.idRelacion ? (
                            <Link
                                to={`/#/${ampliacion.idRelacion}`}
                                target="_blank"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(
                                        `/#/${ampliacion.idRelacion}`,
                                        "_blank",
                                        "noopener,noreferrer"
                                    );
                                }}
                            >
                                {ampliacion?.stro}
                            </Link>
                        ) : (
                            ampliacion?.stro
                        )}
                    </div>
                </th>
                <th>
                    <div className="ampliacionesTexto">{ampliacion?.dominio}</div>
                </th>
                <th className="col-4">
                    <div className="ampliacionesTexto">{ampliacion?.detalle}</div>
                </th>
                <th>
                    <div className="ampliacionesTexto">
                        {formatDate(ampliacion?.entryDate)}
                    </div>
                </th>
                {/* Botón con ID dinámico */}
                {ampliacion?.visto ? (
                    <th>
                        <button
                            className="btn btn-primary btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target={`#modal-${ampliacion?.id}`}
                        >
                            Ver Mas
                        </button>
                        <i class="bi bi-eye-fill ms-2"></i>
                    </th>
                ) : (
                    <th>
                        <button
                            className="btn btn-primary btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target={`#modal-${ampliacion?.id}`}
                        >
                            Ver Mas
                        </button>
                    </th>
                )}
            </tr>
            <div
                className="modal fade"
                id={`modal-${ampliacion?.id}`}
                tabIndex="-1"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Detalles de la Ampliación</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="item-bloque">
                                <strong>Tipo:</strong>{" "}
                                <div className="ampliacionesModalTexto">{ampliacion?.tipo}</div>
                            </div>
                            <div className="item-bloque">
                                <strong>Denuncia:</strong>{" "}
                                <div className="ampliacionesModalTexto">
                                    {ampliacion?.idRelacion ? (
                                        <Link
                                            to={`/#/${ampliacion.idRelacion}`}
                                            target="_blank"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.open(`/#/${ampliacion.idRelacion}`, '_blank', 'noopener,noreferrer');
                                            }}
                                        >
                                            {ampliacion?.denuncia}
                                        </Link>
                                    ) : (
                                        ampliacion?.denuncia
                                    )}
                                </div>
                            </div>
                            <div className="item-bloque">
                                <strong>Póliza:</strong>{" "}
                                <div className="ampliacionesModalTexto">
                                    {ampliacion?.poliza}
                                </div>
                            </div>
                            <div className="item-bloque">
                                <strong>Stro:</strong>{" "}
                                <div className="ampliacionesModalTexto">
                                    {ampliacion?.idRelacion ? (
                                        <Link
                                            to={`/#/${ampliacion.idRelacion}`}
                                            target="_blank"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.open(`/#/${ampliacion.idRelacion}`, '_blank', 'noopener,noreferrer');
                                            }}
                                        >
                                            {ampliacion?.stro}
                                        </Link>
                                    ) : (
                                        ampliacion?.stro
                                    )}
                                </div>
                            </div>
                            <div className="item-bloque">
                                <strong>Dominio:</strong>{" "}
                                <div className="ampliacionesModalTexto">
                                    {ampliacion?.dominio}
                                </div>
                            </div>
                            <div className="item-bloque">
                                <strong>Fecha:</strong>{" "}
                                <div className="ampliacionesModalTexto">
                                    {formatDate(ampliacion?.entryDate)}
                                </div>
                            </div>
                            <div className="item-bloque">
                                <strong>Detalle:</strong>{" "}
                                <div className="ampliacionesModalTexto">
                                    {ampliacion?.detalle}
                                </div>
                            </div>
                            <div className="item-bloque">
                                <strong>Links:</strong>{" "}
                                <div className="ampliacionesModalTexto">
                                    {" "}
                                    {ampliacion?.adjuntos.split(",").map((link, index) => (
                                        <div key={index}>
                                            <a
                                                href={link.trim()}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {link.trim()}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => ampliacionVista(e)}
                            >
                                Marcar como Leido
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
