import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
export default function MenuDerechoEstados({ botonOff, setBotonOff, denuncia }) {
    const elementRef = useRef(null);
    const handleClickOutside = (event) => {
        if (elementRef.current && !elementRef.current.contains(event.target)) {
            setBotonOff(0);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const id = useParams().id;
    const [estados, setEstados] = useState([])
    const [camposEstado, setCamposEstado] = useState([])
    const [valores, setValores] = useState([])
    const [cargando, setCargando] = useState(false);
    const [valoresAct, setValoresAct] = useState({})
    useEffect(() => {
        const fetchData = async () => {
            try {
                const resEstado = await axios.get(`https://back.agsseguros.online/api/estados/todosLosEstados`);
                setEstados(resEstado.data);
                const resCampos = await axios.get(`https://back.agsseguros.online/api/estados/todosLosCampos`);
                setCamposEstado(resCampos.data);
                const resValores = await axios.get(`https://back.agsseguros.online/api/estados/todosLosValores/${id}`);
                setValores(resValores.data);
                setValoresAct(resValores.data);
                setCargando(true);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
        fetchData();
    }, [id]);
    const handleInputChange = (ev, fieldName) => {
        const { type, checked, value } = ev.target;
        setValoresAct(prevState => ({ ...prevState, [fieldName]: type === 'checkbox' ? checked : value }));
    };
    const obtenerFechaHoy = () => {
        const hoy = new Date();
        const dia = String(hoy.getDate()).padStart(2, '0');
        const mes = String(hoy.getMonth() + 1).padStart(2, '0');
        const año = hoy.getFullYear();
        const horas = String(hoy.getHours()).padStart(2, '0');
        const minutos = String(hoy.getMinutes()).padStart(2, '0');
        const segundos = String(hoy.getSeconds()).padStart(2, '0');
        return `${dia}/${mes}/${año} ${horas}:${minutos}:${segundos}`;
    };
    const act = async (e, estado) => {
        e.preventDefault()
        const camposRelacionados = camposEstado.filter(c => c.relacionadoConEstado === estado.id);
        const valoresAEnviar = {};
        if (camposRelacionados.length > 0) {
            const nombreCampoFecha = camposRelacionados[0].nombre;
            valoresAEnviar[nombreCampoFecha] = obtenerFechaHoy();
        }
        camposRelacionados.slice(1).forEach(campo => {
            if (valoresAct[campo.nombre] !== undefined) {
                valoresAEnviar[campo.nombre] = valoresAct[campo.nombre];
            }
        });
        await axios.post(`https://back.agsseguros.online/api/estados/actualizarValoresEstado`, { id: denuncia.id, valores: valoresAEnviar }).then(() => {
            setTimeout(() => { window.location.reload(); }, 500);
        }).catch(error => { console.error('Error al actualizar:', error); });
    }
    return (
        <div className='offCanvasDer shadow pe-3 ps-3 pt-3' ref={elementRef}>
            <div className='d-flex justify-content-end'><button onClick={(e) => setBotonOff(0)} className='btnNO btnSs'><i class="bi bi-x"></i></button></div>
            {cargando ?
                <>
                    {estados.map((estado, index) => {
                        const camposRelacionados = camposEstado.filter(c => c.relacionadoConEstado === estado.id);
                        return (
                            <section className="moduloEstadosContenedor" key={index}>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h2 className=" tituloModuloEstados">{estado.nombre}</h2>
                                    <h2 className={valores[camposRelacionados[0]?.nombre] ? "modificacionModuloEstados" : 'd-none'}><i class="bi bi-calendar-week me-1"></i> Ult Mod: {camposRelacionados.length > 0 ? valores[camposRelacionados[0].nombre] : null}</h2>
                                </div>
                                <form onSubmit={(e) => act(e, estado)}>
                                    <div className="containerEstados">
                                        {camposRelacionados.slice(1).map((campo, campoIndex) => (
                                            <div className="grid-item" key={campoIndex}>
                                                <label htmlFor="" className="labelEstados">{campo.label.toUpperCase()}</label>
                                                {campo.type === 'select' ? (
                                                    <select
                                                    className="form-select form-select-sm rounded-pill"
                                                    value={valoresAct[campo.nombre] || ""}
                                                    onChange={(ev) => handleInputChange(ev, campo.nombre)}
                                                >
                                                    {/* Verificar si el campo es null o vacío */}
                                                    {(valoresAct[campo.nombre] === null || valoresAct[campo.nombre] === "") && (
                                                        <option value="" disabled>Selecciona una opción</option>
                                                    )}
                                                    
                                                    {/* Opciones del campo */}
                                                    {campo.opciones.split(',').map((opcion, opcionIndex) => (
                                                        <option key={opcionIndex} value={opcion.trim()}>
                                                            {opcion.trim()}
                                                        </option>
                                                    ))}
                                                </select>
                                                ) : (
                                                    <input
                                                        type={campo.type}
                                                        className={campo.type === 'checkbox' ? 'form-check-input rounded-pill' : 'form-control form-control-sm rounded-pill'}
                                                        style={
                                                            campo.type === 'date'
                                                                ? { maxWidth: '140px' }
                                                                : campo.type === 'number'
                                                                    ? { maxWidth: '140px' }
                                                                    : null
                                                        }
                                                        value={campo.type !== 'checkbox' ? valoresAct[campo.nombre] : undefined}
                                                        checked={campo.type === 'checkbox' ? valoresAct[campo.nombre] : undefined}
                                                        onChange={(ev) => handleInputChange(ev, campo.nombre)}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center mt-3 mb-4"><button type="submit" className="botonActualizarEstados">Actualizar</button></div>
                                </form>
                            </section>
                        );
                    })}


                </>
                : <div className="SpinnerInTheMiddle center"><div class="spinner-border text-success center" role="status"><span class="visually-hidden center">Loading...</span></div></div>}
        </div>
    )
}